import React from "react";
import Waves from "../../assets/svg/waves/waves";
import "./styles.css";

const Wave: React.FC = () => {
  return (
    <div>
      <Waves />
    </div>
  );
};

export default Wave;
